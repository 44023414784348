import React from "react";

export default function Cookies() {
  return (
    <>
      <div
        className="container-fluid top-cookie-bg d-flex align-items-end px-0 mx-0 pb-5"
        style={{ backgroundImage: "url(img/15-no-bg.png)" }}
      >
        <div className="container-fluid py-2 top-cookie-heading">
          <div className="container d-flex justify-content-between align-items-center py-2">
            <h1 className="cookie-heading">
              Cookie policy of FFR information technology
            </h1>
            <img src="img/3x/logo-white.png" width={80} />
          </div>
        </div>
      </div>

      <div className="container my-5">
        <h2 className="main-color mb-2 mt-4">Overview</h2>
        <p>
          This cookie policy pertains to the website of Dubai-based company
          specialized in the advisory, consultancy and development of visual
          identity and digital marketing projects,{" "}
          <b>FFR INFORMATION TECHNOLOGY.</b> We use cookies and similar
          technologies on our website to enhance the user experience and gain
          insights into the usage of our website.
        </p>
        <h2 className="main-color mb-2 mt-4">What are Cookies?</h2>
        <p>
          Cookies are small text files that are saved on your computer or mobile
          device when you visit a website. These files serve to enhance the user
          experience and provide website owners with information about how their
          website is used.
        </p>
        <h2 className="main-color mb-2 mt-4">Types of Cookies We Use</h2>
        <p>Our website uses the following types of cookies:</p>
        <ul>
          <li className="my-3">
            <b className="main-color ">Essential cookies:</b>These cookies are
            essential for the basic functioning of our website and cannot be
            disabled in our systems.
          </li>
          <li className="my-3">
            <b className="main-color ">Performance cookies:</b>These cookies
            enable us to collect data about the most frequently visited pages
            and any error messages you may encounter. We use this information to
            enhance the performance and user experience of our website.
          </li>
          <li className="my-3">
            <b className="main-color ">Functionality cookies:</b>These cookies
            help us remember your preferences on our website, such as your
            preferred language, and provide a personalized experience.
          </li>
          <li className="my-3">
            <b className="main-color ">Advertising cookies:</b>: These cookies
            are employed to display ads that are more relevant to your
            interests. They are also used to limit the frequency of ad displays
            and measure the effectiveness of advertising campaigns.
          </li>
        </ul>
        <h2 className="main-color mb-2 mt-4">How to Control Cookies?</h2>
        <p>
          You can manage and control cookies on your computer or mobile device
          through your browser settings. It is important to note that disabling
          cookies may affect the functionality of our website.
        </p>
        <h2 className="main-color mb-2 mt-4">
          How to Obtain More Information?
        </h2>
        <p>
          If you have any concerns or queries regarding our cookie policy,
          please do not hesitate to contact us at{" "}
          <a
            className="text-dark fw-bold text-decoration-none"
            href="mailto:info@ffrtechsolutions.com"
          >
            info@ffrtechsolutions.com
          </a>
        </p>

        <p>
          By using our website, you agree to the use of cookies as specified in
          this cookie policy. If you do not consent to the use of cookies, you
          should disable them through your browser settings or refrain from
          using our website.
        </p>
      </div>
    </>
  );
}
