import React from 'react'
import Hero from './Components/Hero'
import WebDesign from './Components/WebDesign'
import Networks from './Components/StorageAndBackup'
import AugmentedSolutions from './Components/AugmentedSolutions'

export default function Consulting() {
  return (
    <>
    <Hero/>
    <AugmentedSolutions/>
    <WebDesign/>
    <Networks/>

    </>
  )
}
