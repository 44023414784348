import React from "react";

export default function ItOutSourcing() {
  return (
    <div id="it-outsource"
      className="container-fluid  it-out-source d-flex flex-wrap align-content-start"
      style={{ backgroundImage: "url(img/9.png)" }}
    >
       <div className="container-fluid">
        <div className="container">
          <div className=" mx-auto cliped-row-it"></div>
        </div>
      </div>
      <div className='container my-5 it-out-content' >
        <div className="row d-flex">
          <div className="col-md-6 row my-auto  text-light">
            <h1>
              <b>IT Outsourcing</b>
            </h1>
            <p>
              Our IT outsourcing services provide businesses with the necessary
              human resources to manage, control, and audit the hardware and
              software resources of their networks. We can provide remote or
              on-site support, depending on the needs of our clients.
            </p>
            <br />
            <br />
            <p>
              By outsourcing IT management, businesses can focus on their core
              operations while leaving the technical aspects to us. Our team has
              the expertise and experience to handle a wide range of IT issues,
              from simple troubleshooting to complex network setups.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
