import React from "react";
import emailjs from "emailjs-com";

export default function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();

    const name = e.target.elements.fname.value;
    const email = e.target.elements.email.value;
    const message = e.target.elements.message.value;

    // Validate form input
    if (!name || !email || !message) {
      alert("All fields are required");
      return;
    }

    // Add regular expression to validate email format
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!email.match(emailRegex)) {
      alert("Invalid email format");
      return;
    }

    emailjs
      .sendForm(
        "Your_Service_ID",
        "Your_Template_ID",
        e.target,
        "Public_User_ID"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message");
        }
      );
  };
  return (
    <div className="container text-light py-5" id="contact">
      <div className="row">
        <div className="col-md-10 ms-auto contact-wrapper p-4 shadow-lg">
          <div className="row">
            <div
              className="col-md-5  d-flex  flex-wrap p-4 align-content-between contact-us-img"
              style={{ backgroundImage: "url(img/3.png)" }}
            >
              <h2 className="text-center w-100">
                <b>Contact Us</b>
              </h2>
              <p>
                FFR INFORMATION TECHNOLOGY
                <br />
                UAE, DUBAI. CBD Bank Building
                <br />
                2nd Floor, office number 67, Al Fahidi
                <br />
                <a
                  className="text-light"
                  href="https://www.ffrtechsolutions.com"
                >
                  www.ffrtechsolutions.com
                </a>
              </p>
            </div>
            <div className="col-md-7 m-5 py-5 px-1 contact-form">
              <form onSubmit={sendEmail}>
                <div className="row ">
                  <div className="my-3 col-md-6">
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="firstName"
                      placeholder="First Name"
                      name="fname"
                    />
                  </div>
                  <div className="my-3 col-md-6">
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="lastName"
                      placeholder="Last Name"
                      name="lname"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="my-3 col-md-6">
                    <input
                      type="email"
                      className="form-control custom-input"
                      id="email"
                      placeholder="Email"
                      name="email"
                    />
                  </div>
                  <div className="my-3 col-md-6">
                    <input
                      type="tel"
                      className="form-control custom-input"
                      id="phone"
                      placeholder="Phone"
                      name="phone"
                    />
                  </div>
                </div>
                <div className="my-3">
                  <textarea
                    className="form-control custom-input"
                    id="message"
                    rows="3"
                    placeholder="Message"
                    name="message"
                  ></textarea>
                </div>
                <div className="row">
                  <div className="12 justify-content-center d-flex">
                    <button
                      type="submit"
                      className="btn main-bg text-light mx-auto"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
