import React from "react";

export default function Hero() {
  return (
    <>
      <div
        className="container-fluid bg-image-top "
        style={{ backgroundImage: `url(/img/4.png)` }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className="container justify-content-center d-flex align-items-center flex-wrap"
        >
          <div className="row w-100">
            <div className="col-md-7 col-lg-6 text-light d-flex flex-wrap">
              <h1>
                <span style={{ fontSize: "6rem", fontWeight: "700" }}>FFR</span>{" "}
              </h1>
              <p className="justify ">
                The digital transformation of businesses has accelerated the
                need for cloud solutions, networks computer communications,
                mobility, and collaborative environments. These technology
                components have become essential for businesses to stay
                competitive, efficient, and agile in today's fast-paced
                environment.
              </p>
              <a href="#coud-solutions" className="btn btn-light ms-auto">
                More Info <img src="img/down_arrow.png" width={12} />
              </a>
            </div>
          </div>
        </div>

        <div className="container" id="coud-solutions">
          <div className=" mx-auto cliped-row-reverse"></div>
        </div>
      </div>
    </>
  );
}
