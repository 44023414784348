import React from "react";

export default function Hero() {
  return (
    <>
      <div
        className="container-fluid bg-image-top "
        style={{ backgroundImage: `url(/img/11.png)` }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className="container justify-content-center d-flex align-items-center flex-wrap"
        >
          <div className="row w-100">
            <div className="col-md-7 col-lg-6 text-light d-flex flex-wrap">
              <h1>
                <span style={{ fontSize: "6rem", fontWeight: "700" }}>FFR</span>{" "}
              </h1>
              <p className="justify ">
                As businesses become increasingly reliant on technology, they
                require specialized expertise to ensure their IT infrastructure
                is secure, efficient, and aligned with their overall objectives.
                In this context, consulting and technical advice, IT
                outsourcing, and cybersecurity are three critical components
                that can help businesses navigate the complex and rapidly
                evolving technology landscape.
              </p>

              <a href="#consulting-advice" className="btn btn-light ms-auto">
                More Info <img src="img/down_arrow.png" width={12} />
              </a>
            </div>
          </div>
        </div>

        <div className="container" id="consulting-advice">
          <div className=" mx-auto cliped-row"></div>
        </div>
      </div>
    </>
  );
}
