import React from 'react'
import Hero from './Components/Hero'
import ConsultingAndAdvice from './Components/ConsultingAndAdvice'
import ItOutSourcing from './Components/ItOutSourcing'
import Cybersecurity from './Components/Cybersecurity'

export default function Consulting() {
  return (
    <>
    <Hero/>
    <ConsultingAndAdvice/>
    <ItOutSourcing/>
    <Cybersecurity/>

    </>
  )
}
