import React from "react";
import { Link } from "react-router-dom";

export default function Navbar(props) {
  return (
    <>
      <nav class="navbar fixed-top container-fluid navbar-expand-lg navbar-top">
        <div class="container">
          <Link class="navbar-brand" to="/">
            <img src="/img/3x/logo.png" width={60} />
          </Link>
          <button
            class="navbar-toggler main-color"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0 nav-fill">

              <li class="nav-item mx-3">
                <a
                  class="nav-link main-color active"
                  aria-current="page"
                  href="#tech-solutions"
                >
                  TECH SOLUTIONS
                </a>
              </li>
              <li class="nav-item mx-3">
                <a
                  class="nav-link main-color active"
                  aria-current="page"
                  href="#about"
                >
                  ABOUT US
                </a>
              </li>
              <li class="nav-item mx-3">
                <a
                  class="nav-link main-color active"
                  aria-current="page"
                  href="#team"
                >
                  TEAM
                </a>
              </li>

              <li class="nav-item mx-3 dropdown">
                <a
                  class="nav-link main-color dropdown-toggle"
                  href="#"
                  id="servicesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  SERVICES
                </a>
                <ul
                  class="dropdown-menu dropdown-blured"
                  aria-labelledby="servicesDropdown"
                >
                  <li>
                    <Link class="dropdown-item main-color" to="/consulting#consulting-advice">
                      Consulting and Technical Advice
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item main-color" to="/consulting#it-outsource">
                      IT Outsourcing
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item main-color"
                      to={{ pathname: "/consulting", hash: "#cybersecurity" }}
                    >
                      Cybersecurity
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item main-color"
                      to="/cloud-solutions#coud-solutions"
                    >
                      Cloud Solutions
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item main-color"
                      to="/cloud-solutions#networks"
                    >
                      Networks Computer Communications
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item main-color"
                      to="/cloud-solutions#mobility"
                    >
                      Mobility and Collaborative Environments
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item main-color" to="/ar-vr#ar">
                      AR and VR Solutions
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item main-color" to="/ar-vr#web-design">
                      Web Design
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item main-color" to="/ar-vr#storage">
                      Storage and Backup
                    </Link>
                  </li>
                </ul>
              </li>

              <li class="nav-item mx-3">
                <a class="nav-link main-color" href="#contact">
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
