import React from "react";

export default function Hero() {
  return (
    <>
      <div id="home"
        className="container-fluid bg-image-top "
        style={{ backgroundImage: `url(/img/5.png)` }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className="container justify-content-center d-flex align-items-center flex-wrap"
        >
          <div className="row w-100">
            <div className="col-md-7 col-lg-6 text-light d-flex flex-wrap">
              <h1>
                <span style={{ fontSize: "6rem", fontWeight: "700" }}>FFR</span>{" "}
                <br />
                <span style={{ fontSize: "4rem", fontWeight: "500" }}>
                  Tech Solutions
                </span>
              </h1>
              <p className="justify ">
                At FFR we are specialists in providing computer network and
                communication services for all types of companies, including
                small businesses, corporations, and everything in between. We
                also offer consulting and customized Virtual Reality and
                Augmented Reality project creation services.
              </p>

              <a href="#tech-solutions" className="btn btn-light ms-auto">
                More Info <img src="img/down_arrow.png" width={12} />
              </a>
            </div>
          </div>
        </div>

        <div className="container" id="tech-solutions">
          <div className=" mx-auto cliped-row"></div>
        </div>
      </div>
    </>
  );
}
