import React from "react";

export default function Team() {
  return (
    <div
      className="container-fluid bg-image-team text-light team" id="team"
      style={{ backgroundImage: `url(/img/20.png)` }}
    >
      <div className="team-content col-md-7 col-11 p-5">
        <h1>
          <b>TEAM</b>
        </h1>
        <p style={{fontSize:'20px'}}>
          We have a highly trained and experienced team in the design,
          implementation, and maintenance of technology solutions for companies.<br/>
          Our specialists in computer networks, communications, virtual and
          augmented reality, and technology consulting work together to provide
          customized solutions tailored to the needs of our customers.<br/> We are
          committed to the continuous training of our team to stay up-to-date on
          the latest technologies and trends in the market.
        </p>
      </div>
    </div>
  );
}
