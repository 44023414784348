import React from "react";

export default function About() {
  const data = [
    {
      heading: "Excellence:",
      content:
        "We strive to provide the highest quality solutions and excellence for our clients.",
    },
    {
      heading: "Innovation:",
      content:
        "We stay at the forefront of the latest technologies and trends to offer innovative solutions to our customers.",
    },
    {
      heading: "Adaptability:",
      content:
        "We adapt quickly to changes and challenges to meet our customers' needs in a constantly evolving environment.",
    },
    {
      heading: "Continuous learning:",
      content:
        "We promote a continuous learning environment for our team to stay current on the latest technologies and industry trends.",
    },
    {
      heading: "Sustainability:",
      content:
        "We are aware of the environmental and social impact of our operations and strive to be a sustainable and responsible company.",
    },
  ];

  return (
    <div className="container-fluid  about-us" id="about">
      <div className='container "'>
        <div className="row p-3 d-flex">
          <div className="col-md-6 row align-items-stretch">
            <div className="col-md-6 my-4">
              <div
                className="col-12  small-div-img p-5 pb-1 px-2 rounded-2 text-light justify-content-end"
                style={{ backgroundImage: "url(img/12.png)" }}
              >
                <h4>
                  <b>MISSION</b>
                </h4>
                <small className="justify v-small-text">
                  Our mission is to be leaders in the provision of computer
                  network and communications services for all types of
                  companies, as well as in the consulting and creation of
                  customized VR and AR projects. We strive to improve the
                  efficiency and competitiveness of our customers by offering
                  customized solutions that simplify network management and
                  optimize business processes.
                </small>
              </div>
            </div>
            <div className="col-md-6 my-4">
              <div
                className="col-12  small-div-img p-5 pb-1 px-2 rounded-2 text-light justify-content-end"
                style={{ backgroundImage: "url(img/13.png)" }}
              >
                <h4>
                  <b>VISION</b>
                </h4>
                <small className="justify v-small-text">
                  Our vision is to be recognized as one of the best technology
                  solutions provider in the market, standing out for our
                  excellence in customer service, innovation, and quality of our
                  services.
                </small>
              </div>
            </div>
            <div className="col-12">
              <div
                className="col-12 rounded-2 mx-auto small-div-img-2 p-5"
                style={{ backgroundImage: "url(img/10.png)" }}
              >
                <h1 className="text-center my-5 mx-0 text-light">
                  Empowering a smarter future with innovative technology
                  solutions.
                </h1>
              </div>
            </div>
          </div>

          <div className="col-md-6 text-light mt-4">
            {data.map((item, index) => {
              return (
                <div key={index} className="col-12 my-2">
                  <h3>
                    <b>{item.heading}</b>
                  </h3>
                  <div className="col-md-8 ms-auto ">
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
