import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {
    // Get the hash from the URL
    const hash = window.location.hash;

    if (hash) {
      console.log(hash.slice(1));
      // Scroll to the element with the matching ID
      const element = document.getElementById(hash.slice(1));

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if no hash is found
      window.scrollTo(0, 0);
    }
    console.log(1);
  }, [location]);

  return null;
}

export default ScrollToSection;
