import React from 'react'
import Hero from './Components/Hero'
import TechSolution from './Components/TechSolution'
import About from './Components/About'
import Team from './Components/Team'
import BusinessSolution from './Components/BusinessSolution'
import ScrollToTop from '../../Components/ScrollToTop'

export default function Home() {
  return (
    <>
  
    <Hero/>
    <TechSolution/>
    <About/>
    <Team/>
    <BusinessSolution/>
    </>
  )
}
