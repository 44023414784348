import React, { useEffect } from "react";

import Home from "./Pages/Home/Home";
import Consulting from "./Pages/Consulting/Consulting";
import CloudSolutions from "./Pages/CloudSolutions/CloudSolutions";
import AR_VR from "./Pages/AR_VR/AR_VR";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from "./Components/ScrollToTop";
import ScrollToTopButton from "./Components/ScrollToTopButton";

import CookieBanner from "./Components/CookieBanner";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import PageNavbar from "./Components/PageNavbar";
import ScrollToSection from "./Components/ScrollToSection";
import Cookies from "./Pages/Cookies";
import LegalNotice from "./Pages/LegalNotice";
import Privacy from "./Pages/Privacy";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
              </>
            }
          />
          <Route
            path="/consulting"
            element={
              <>
                <PageNavbar />
                <Consulting />
              </>
            }
          />
          <Route
            path="/cloud-solutions"
            element={
              <>
                <PageNavbar />
                <CloudSolutions />
              </>
            }
          />
          <Route
            path="/ar-vr"
            element={
              <>
                <PageNavbar />
                <AR_VR />
              </>
            }
          />
          <Route
            path="/cookies"
            element={
              <>
                <PageNavbar />
                <Cookies />
              </>
            }
          />
          <Route
            path="/legal"
            element={
              <>
                <PageNavbar />
                <LegalNotice />
              </>
            }
          />
          <Route
            path="/privacy"
            element={
              <>
                <PageNavbar />
                <Privacy />
              </>
            }
          />
        </Routes>
        <Contact />
        <Footer />
        <ScrollToSection />
        <ScrollToTopButton />
        <CookieBanner />
      </Router>
    </>
  );
}

export default App;
