import React from "react";

export default function TechSolution() {
  return (
    <>
      <div className=""></div>
      <div className="container-fluid "  >
        <div className="container  clip-div">
          <div className="row ">
            <div className="col-md-6 main-color">
              <div className="col-md-11 mx-auto">
                <h2>
                  <b>TECH SOLUTIONS</b>
                </h2>
                <p className="justify">
                  Our main objective is to streamline the daily processes of our
                  customers by offering constant support to improve the
                  efficiency and competitiveness of their businesses. <br />
                  <br />
                  We provide customized solutions that enable the integration of
                  projects, thereby optimizing and automating processes to
                  reduce costs and margin of error.
                  <br />
                  <br /> Our solutions also increase business agility and
                  simplify network management. <br />
                  <br />
                  We accompany our customers through all stages of their
                  projects, starting from when they first communicate their
                  needs to us. We then develop the most appropriate solution for
                  each specific case, and once implemented, we provide
                  maintenance and support.
                </p>
              </div>
            </div>
            <div className="col-md-6 px-3 d-flex ">
              <div
                className="col-md-10 mx-auto div-img my-auto"
                style={{ backgroundImage: "url(img/19.png)" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="bottom-cliped"
            style={{ height: "100px", width: "100%" }}
          ></div>
        </div>
      </div>
    </>
  );
}
