import React from "react";

export default function Cloud() {
  return (
    <>
      <div className=""></div>
      <div className="container-fluid ">
        <div className="container  clip-div">
          <div className="row my-2">
            <div className="col-md-6 px-3 d-flex">
              <div
                className="col-md-10 mx-auto div-img-reverse my-auto"
                style={{ backgroundImage: "url(img/6.png)" }}
              ></div>
            </div>
            <div className="col-md-6 main-color d-flex flex-wrap">
              <div className="col-md-11 mx-auto my-auto">
                <h2>
                  <b>Cloud Solutions</b>
                </h2>
                <p className="justify p-tag-dark">
                  Businesses in an increasingly digital environment need to have
                  secure and reliable information storage and sharing solutions.
                  <br />
                  <br />
                  That's why we offer our cloud-based solutions, which enable
                  companies to store and share company information and data
                  anytime, anywhere.
                  <br />
                  <br />
                  Thanks to the cloud, our customers can access their data
                  securely from any device, and we guarantee the availability
                  and security of information at all times.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div
            className="bottom-cliped"
            style={{ height: "100px", width: "100%" }}
          ></div>
        </div> */}
      </div>
    </>
  );
}
