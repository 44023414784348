import React from "react";

export default function Privacy() {
  return (
    <>
      <div
        className="container-fluid top-cookie-bg d-flex align-items-end px-0 mx-0 pb-5"
        style={{ backgroundImage: "url(img/11-no-bg.png)" }}
      >
        <div className="container-fluid py-2 top-cookie-heading">
          <div className="container d-flex justify-content-between align-items-center py-2">
            <h1 className="cookie-heading">
              Privacy policy of FFR information technology
            </h1>
            <img src="img/3x/logo-white.png" width={80} />
          </div>
        </div>
      </div>

      <div className="container my-5">
        <p>
          At <b>FFR INFORMATION TECHNOLOGY</b> we value your privacy and are
          committed to protecting your personal information. This Privacy Policy
          describes how we collect, use, and share the personal information we
          obtain through our website and online services. By using our website
          or services, you agree to the terms of this Privacy Policy.
        </p>
        <h2 className="main-color mb-2 mt-4">
          Collection of Personal Information
        </h2>
        <p>
          We collect personal information that you directly provide through our
          website or services, such as your name, email address, mailing
          address, phone number, and other contact information. We may also
          collect information about your use of our website and services,
          including your IP address, browser type, operating system, pages
          visited, location information, and other log information.
        </p>
        <h2 className="main-color mb-2 mt-4">Use of Personal Information</h2>
        <p>
          We use your personal information to provide you with our products and
          services, respond to your inquiries, personalize your user experience,
          administer your account, and send you promotional communications. We
          may also use your personal information to improve our products and
          services and for internal purposes, such as data analytics and
          compliance with our legal obligations.
        </p>

        <h2 className="main-color mb-2 mt-4">
          Disclosure of Personal Information
        </h2>
        <p>
          We may share your personal information with third parties who assist
          us in providing our products and services, including hosting service
          providers, payment service providers, and marketing service providers.
          We may also share your personal information when necessary to comply
          with applicable law, respond to a court order, or protect our legal
          rights.
        </p>
        <h2 className="main-color mb-2 mt-4">
          Security of Personal Information
        </h2>
        <p>
          We are committed to protecting the security of your personal
          information and have implemented reasonable technical and
          organizational security measures to protect it against unauthorized
          access, alteration, disclosure, or misuse.
        </p>
        <h2 className="main-color mb-2 mt-4">
          Retention of Personal Information
        </h2>
        <p>
          We retain your personal information for as long as necessary to
          fulfill the purposes for which it was collected or to comply with our
          legal obligations, unless a longer retention period is required or
          permitted by law.
        </p>
        <h2 className="main-color mb-2 mt-4">Privacy Rights</h2>
        <p>
          You have certain privacy rights in relation to your personal
          information, such as the right to access, rectify, and delete your
          personal information. You also have the right to object to the
          processing of your personal information in certain circumstances. If
          you wish to exercise any of these rights, please contact us using the
          contact information provided below.
        </p>
        <h2 className="main-color mb-2 mt-4">Changes to the Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our privacy practices. We recommend that you periodically review
          this Privacy Policy to stay informed of any significant changes. If we
          make material changes to this Privacy Policy, we will notify you by
          posting an updated version on our website.
        </p>
        <h2 className="main-color mb-2 mt-4">Contact</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us through:
        </p>
        <br />
        <p>Company Address:</p>
        <h3 className="main-color mb-4">FFR INFORMATION TECHNOLOGY</h3>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            CBD Bank Building
            <br />
            2nd Floor, office number 67, Al Fahidi
            <br />
            DUBAI, UAE.
            <br />
            <a
              className=" text-dark fw-bold text-decoration-none"
              href="https://www.ffrtechsolutions.com/"
            >
              www.ffrtechsolutions.com
            </a>
          </div>
          <div className="col-12 text-end main-color">
            Effective Date: April 11, 2023
          </div>
        </div>
      </div>
    </>
  );
}
