import React from "react";

export default function StorageAndBackup() {
  return (
    <div
      className="container-fluid  backup-img p-0 flex-wrap"
      style={{ backgroundImage: "url(img/8.png)" }}
    >
      <div className="container-fluid" id="storage">
        <div className="container">
          <div className=" mx-auto cliped-row-v"></div>
        </div>
      </div>
      <div className="container-fluid backup-content m-0 w-100">
        <div className='container my-auto mt-2 mb-4"'>
          <div className="row  w-100 py-3 d-flex">
            <div className="w-100 row my-auto  text-light">
              <h1>
                <b>Storage and Backup</b>
              </h1>
              <p className="justify">
                Our storage and backup services provide businesses with a
                customized solution for the storage and recovery of information
                and data in case of disasters where businesses are highly
                vulnerable. We understand that data is a critical asset for
                businesses, which is why we take care to ensure that our
                clients' data is secure and easily recoverable in case of any
                data loss incidents.
              </p>
              <br />
              <br />
              <p className="justify">
                We work with our clients to understand their data storage and
                backup needs and provide customized solutions that fit their
                specific requirements. Our team can help design and implement a
                backup and disaster recovery plan that ensures minimal downtime
                and data loss in case of any unexpected events.
              </p>
              <br />
              <br />
              <p className="mb-3 justify">
                We also offer data migration services, where we help usinesses
                move their data from one system to another, ensuring a smooth
                transition with minimal disruption to their operations.
              </p>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
