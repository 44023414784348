import React from "react";

export default function Hero() {
  return (
    <>
      <div
        className="container-fluid bg-image-top "
        style={{ backgroundImage: `url(/img/17.png)` }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className="container justify-content-center d-flex align-items-center flex-wrap"
        >
          <div className="row w-100">
            <div className="col-md-7 col-lg-6 text-light d-flex flex-wrap">
              <h1>
                <span style={{ fontSize: "6rem", fontWeight: "700" }}>FFR</span>{" "}
              </h1>
              <p className="justify ">
                Whether you're looking to take your customer engagement to the
                next level, create an online presence that sets you apart from
                the competition, or ensure that your critical business data is
                always secure and accessible, our augmented reality and virtual
                reality solutions, web design, and storage and backup services
                are here to help.
              </p>
              <a href="#ar" className="btn btn-light ms-auto">
                More Info <img src="img/down_arrow.png" width={12} />
              </a>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <div className=" mx-auto cliped-row-reverse"></div>
        </div> */}
      </div>
    </>
  );
}
