import React from "react";

export default function Mobility() {
  return (
    <div
      className="container-fluid  text-light team"
      id="mobility"
      style={{ backgroundImage: `url(/img/15.png)` }}
    >
      <div className=" container p-5">
        <h1>
          <b>Mobility and Collaborative</b>
          <br />
          <b>Environments</b>
        </h1>

        <div className="row">
          <div className="col-md-6">
            <p>
              Our customers, in an everyday more mobile world, need solutions
              that allow them to access corporate applications and data
              efficiently and securely. To this end, we offer remote connections
              that allow users to access information from anywhere. In addition,
              we provide teamwork solutions that enable efficient data sharing
              and task management. We connect people and projects thanks to our
              solutions for mobility and collaborative work.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
