import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer class="main-bg text-white py-4">
      <div class="container-fluid">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-4">
              <img
                src="img/3x/logo-white.png"
                width={70}
                alt="Logo"
                class="img-fluid mb-2"
              />
              <hr class="bg-white" />
              <p>
                Empowering a smarter future
                <br />
                with innovative
                <br />
                technology solutions
              </p>
            </div>
            <div class="col-md-8  row">
              <div className="col-12 d-flex justify-content-end">
                <img
                  src="img/3x/insta.png"
                  alt="Insatagram"
                  class="img-fluid mx-3 "
                  width={50}
                />
                <img
                  src="img/3x/facebook.png"
                  alt="Facebook"
                  class="img-fluid mx-3 "
                  width={50}
                />
                <img
                  src="img/3x/linkedin.png"
                  alt="Linkedin"
                  class="img-fluid mx-3"
                  width={50}
                />
              </div>
              <div className="col-12 mt-4 d-flex justify-content-end">
                <p className="border-end px-2">
                  <Link
                    className="text-light text-decoration-none"
                    to="/privacy"
                  >
                    Privacy Policy
                  </Link>
                </p>
                <p className="border-end px-2">
                  <Link
                    className="text-light text-decoration-none"
                    to="/cookies"
                  >
                    Cookies Policy
                  </Link>
                </p>
                <p className=" px-2">
                  <Link
                    className="text-light text-decoration-none"
                    to="/legal"
                  >
                    Legal Notice
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
