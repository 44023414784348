import React from "react";

export default function ConsultingAndAdvice() {
  return (
    <>
      <div className=""></div>
      <div className="container-fluid ">
        <div className="container  clip-div">
          <div className="row ">
            <div className="col-md-6 main-color">
              <div className="col-md-11 mx-auto">
                <h2>
                  <b>Consulting and Technical Advice</b>
                </h2>
                <p className="justify p-tag-dark">
                  Our consulting and technical advice services provide
                  businesses with expert guidance on how to leverage technology
                  to achieve their goals. Our team of specialists works closely
                  with our clients to understand their business objectives,
                  existing infrastructure, and budgetary constraints, and then
                  advises and designs technological protocols that best suit
                  each specific case.
                  <br />
                  <br />
                  We take a comprehensive approach to our consulting and
                  technical advice services, analyzing all aspects of our
                  clients' IT infrastructure, including hardware, software, and
                  networks. Based on our analysis, we provide recommendations
                  for technology solutions that can help streamline processes,
                  reduce costs, and improve efficiency.
                  <br />
                  <br />
                  We understand that every business is unique, which is why we
                  provide customized solutions that fit each client's specific
                  needs. Our team has extensive experience in a variety of
                  industries, including healthcare, finance, retail, and
                  manufacturing, among others, and can provide specialized
                  guidance for each industry's specific requirements.
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-6 px-3 d-flex">
              <div
                className="col-md-10 mx-auto div-img my-auto"
                style={{ backgroundImage: "url(img/14.png)" }}
              ></div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div
            className="bottom-cliped"
            style={{ height: "100px", width: "100%" }}
          ></div>
        </div> */}
      </div>
    </>
  );
}
