import React from "react";

export default function Networks() {
  return (
    <div id="networks"
      className="container-fluid  it-out-source d-flex flex-wrap align-content-start"
      style={{ backgroundImage: "url(img/2.png)" }}
    >
        <div className="container-fluid">
        <div className="container">
          <div className=" mx-auto cliped-row-it-x"></div>
        </div>
      </div>
      <div className='container my-auto "'>
        <div className="row p-3 d-flex">
          <div className="col-md-8 row my-auto  text-light">
            <h1>
              <b>Networks</b>
              <br />
              <b>Computer Communications</b>
            </h1>
            <p>
              Designing a computer network is not an easy task, since factors
              such as the size of the company, the number of users, available
              resources, among others, must be considered.
            </p>
            <br />
            <br />
            <p>
              In our company, we design customized computer networks for each
              client, considering the specific needs of their business.
            </p>
            <br />
            <br />
            <p>
              We offer solutions that include infrastructure in servers, storage
              and local networks and/or in the cloud, always under the most
              appropriate environment for each business.
            </p>
            <br />
            <br />
            <p>
              We guarantee an optimal and secure operation of the network, so
              that our customers can focus on their business.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
