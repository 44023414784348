import React from "react";

export default function LegalNotice() {
  return (
    <>
      <div
        className="container-fluid top-cookie-bg d-flex align-items-end px-0 mx-0 pb-5"
        style={{ backgroundImage: "url(img/13-no-bg.png)" }}
      >
        <div className="container-fluid py-2 top-cookie-heading">
          <div className="container d-flex justify-content-between align-items-center py-2">
            <h1 className="cookie-heading">
              Legal notice of FFR information technology
            </h1>
            <img src="img/3x/logo-white.png" width={80} />
          </div>
        </div>
      </div>

      <div className="container my-5">
        <h2 className="main-color mb-2 mt-4">Overview</h2>
        <p>
          Welcome to <b>FFR INFORMATION TECHNOLOGY</b>, a Dubai-based company
          specialized in the advisory, consultancy and development of visual
          identity and digital marketing projects. By using our services and
          website, you agree to these terms and conditions of use. If you do not
          agree to these terms, you may not use our services.
        </p>
        <h2 className="main-color mb-2 mt-4">Company information</h2>
        <p>
          <b> FFR INFORMATION TECHNOLOGY </b>is a company with its registered
          office at CBD Bank Building 2nd Floor, office number 67, Al Fahidi,
          DUBAI, UAE. It is registered with the Department of Economic
          Development of Dubai (DED) and has the necessary permits and licenses
          to operate
          <b className="main-color"> (license No. 994270).</b>
        </p>
        <h2 className="main-color mb-2 mt-4">Intellectual property</h2>
        <ol type="a">
          <li className="a-li my-3">
            All intellectual property rights related to our website and
            services, including but not limited to copyright, trademarks, and
            patents, are the exclusive property of FFR INFORMATION TECHNOLOGY
            and are protected by applicable intellectual property laws.
          </li>
          <li className="a-li my-3">
            Reproduction, distribution, or modification of any content on our
            website or services without our prior written consent is not
            allowed.
          </li>
        </ol>
        <h2 className="main-color mb-2 mt-4">Limitation of liability</h2>
        <p>Our website uses the following types of cookies:</p>
        <ol type="a">
          <li className="a-li my-3">
            We will not be liable for any loss or damage arising from the use of
            our website or services, including but not limited to any loss of
            profits, loss of data, loss of business, or interruption of business
            activity.
          </li>
          <li className="a-li my-3">
            We strive to provide accurate and up-to-date information on our
            website and services, but we do not guarantee the accuracy,
            completeness, or timeliness of such information.
          </li>
          <li className="a-li my-3">
            We reserve the right to modify or remove any content or service on
            our website without notice.
          </li>
        </ol>
        <h2 className="main-color mb-2 mt-4">Links to third-party websites</h2>
        <p>
          Our website may contain links to third-party websites that are not
          under our control. We are not responsible for the content or privacy
          practices of these websites.
        </p>
        <h2 className="main-color mb-2 mt-4">
          Applicable law and jurisdiction
        </h2>
        <p>
          These terms and conditions shall be governed and interpreted in
          accordance with the laws of the United Arab Emirates, and any dispute
          arising in connection with these terms and conditions shall be subject
          to the exclusive jurisdiction of the courts of Dubai.
        </p>

        <p>
          If you have any questions or concerns about our terms and conditions,
          please feel free to contact us at{" "}
          <a
            className="text-dark fw-bold text-decoration-none"
            href="mailto:info@ffrtechsolutions.com"
          >
            info@ffrtechsolutions.com
          </a>
        </p>
      </div>
    </>
  );
}
