import React from "react";

export default function Cybersecurity() {
  return (
    <div
      className="container-fluid bg-image-team text-light team "
       id="cybersecurity"
      style={{ backgroundImage: `url(/img/7.png)` }}
    >


      <div className="team-content col-md-7 col-11 p-5">
        <h1>
          <b>Cybersecurity</b>
        </h1>
        <p style={{ fontSize: "20px" }}>
          In today's digital landscape, cybersecurity is of utmost mportance for
          businesses of all sizes.
          <br />
          <br />
          Our cybersecurity services are designed to protect usinesses from
          possible attacks using systems that analyze vulnerabilities and
          security needs to minimize risks.
          <br />
          <br />
          Our team of experts can help identify potential risks and implement
          measures that ensure our clients' networks are secure. We offer a
          range of services, including security audits, risk assessments, and
          implementation of security solutions.
        </p>
      </div>
    </div>
  );
}
