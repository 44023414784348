import React from "react";
import { Link } from "react-router-dom";

export default function BusinessSolution() {
  const data = [
    {
      title: "Consulting and Technical Advice",
      hash: "/consulting#consulting-advice",
    },
    {
      title: "IT Outsourcing",
      hash: "/consulting#it-outsource",
    },
    {
      title: "Cybersecurity",
      hash: "/consulting#cybersecurity",
    },
    {
      title: "Cloud Solutions",
      hash: "/cloud-solutions#coud-solutions",
    },
    {
      title: "Networks Computer Communications",
      hash: "/cloud-solutions#networks",
    },
    {
      title: "Mobility and Collaborative Environments",
      hash: "/cloud-solutions#mobility",
    },
    {
      title: "Augmented Reality and Virtual Reality Solutions",
      hash: "/ar-vr#ar",
    },
    {
      title: "Web Design",
      hash: "/ar-vr#web-design",
    },
    {
      title: "Storage and Backup",
      hash: "/ar-vr#storage",
    },
  ];

  return (
    <div className="container-fluid   business-solution text-light">
      <div className="container py-5 ">
        <div className="row align-items-center">
          <div className="col-md-5">
            <h1>
              <b>IT BUSINESS SOLUTIONS</b>
            </h1>
            <p>
              We are committed to understanding the unique needs of each
              customer and dapting our solutions to achieve their goals as
              quickly as possible.
              <br />
              <br /> To achieve this, we have certified specialists and
              professional IT services to advise and cover all the technological
              needs of the company.
              <br />
              <br /> Our services include consulting and technical advice,
              professional IT support, IT outsourcing, cybersecurity, and
              storage and backup solutions.
            </p>
          </div>
          <div className="col-md-7">
            <div class="container">
              <div class="row g-0 bg-dark bg-image">
                {data.map((item, index) => {
                  return (
                      <Link to={item.hash} class="col-md-4 service-div-sm col-6 text-light text-decoration-none text-center" key={index}>

                        <div class="grid-item h-100 p-3 d-flex justify-content-center align-items-center">
                          <p style={{ fontSize: "17px" }}>
                            <b>{item.title}</b>
                          </p>

                    </div>
                      </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row business-bottom-clip"></div> */}
      </div>
    </div>
  );
}
