import React from "react";

export default function WebDesign() {
  return (
    <>
      <div className=""></div>
      <div className="container-fluid ">
        <div className="container  clip-div" id="web-design">
          <div className="row my-5">
            <div className="col-md-6 main-color d-flex flex-wrap">
              <div className="col-md-11 mx-auto my-auto">
                <h2>
                  <b>Web Design</b>
                </h2>
                <p className="justify p-tag-dark">
                  Nowadays, having a website is essential for any business, as
                  it is one of the main ways to reach potential customers.
                  <br />
                  <br />
                  We design custom web design services, adapted to any device.
                  In addition, we provide a content management system that
                  allows our clients to be their own administrators and
                  self-manage the website according to the needs of their
                  business.
                  <br />
                  <br />
                  We guarantee an attractive and functional design, allowing our
                  clients to stand out in the digital world.
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-6 px-3 d-flex">
              <div
                className="col-md-10 mx-auto div-img-v my-auto"
                style={{ backgroundImage: "url(img/1.png)" }}
              ></div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div
            className="bottom-cliped"
            style={{ height: "100px", width: "100%" }}
          ></div>
        </div> */}
      </div>
    </>
  );
}
