import React from 'react'
import Hero from './Components/Hero'
import Networks from './Components/Networks'
import Mobility from './Components/Mobility'
import Cloud from './Components/Cloud'

export default function Consulting() {
  return (
    <>
    <Hero/>
    <Cloud/>
    <Networks/>
    <Mobility/>

    </>
  )
}
